import {CommonModule} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Article} from '@worxs/cms/types/article';
import {SingleTypesService} from '../../services/single-types.service';
import {SoMeLinks} from '@worxs/cms/types/some-links';

@Component({
  selector: 'app-so-me',
  templateUrl: './so-me.component.html',
  standalone: true,
  styleUrls: ['./so-me.component.css'],
  imports: [
    CommonModule
  ]
})
export class SoMeComponent implements OnInit {

  SoMeLinks?: SoMeLinks;

  @Input() share = false;
  @Input() article?: Article;
  @Input() background = 'dark';
  @Input() buttonsOnly = false;
  url?: string;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private singleTypesService: SingleTypesService
  ) {
    this.url = document.location.href;
  }

  ngOnInit() {
    this.singleTypesService.getSoMeLinks().subscribe((soMeLinks) => {
      this.SoMeLinks = soMeLinks;
    });
  }

  facebookShareLink(): string {
    return 'https://www.facebook.com/sharer/sharer.php?href=' + this.url;
  }

  linkedInShareLink(article: Article | undefined): string {
    if (!article && this.SoMeLinks && this.SoMeLinks.attributes.linkedin) {
      return this.SoMeLinks.attributes.linkedin;
    }

    if (article) {
      return `https://www.linkedin.com/shareArticle
      ?mini=true
      &url=${this.url}
      &title=${article.attributes.title}
      &summary=${article.attributes.abstract}
      &source=WORXS`;
    }

    return '';
  }
}
